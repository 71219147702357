ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* background-color: rgb(190, 158, 120); */
}

li {
  float: left;
}

li a, .dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 8px;
  text-decoration: none;
}

li a:hover, .dropdown:hover .dropbtn {
  background-color: lightgoldenrodyellow;
}

li.dropdown {
  display: inline-block;
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 4%;
    height: 4.5%;
    left: 8%;
    top: 5%;
  }
   
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background:rgb(121, 66, 66);
  }
   
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 5%;
    width: 5%;
  }
   
  /* Color/shape of close button cross */
  .bm-cross {
    background: red;
  }
   
  /* General sidebar styles */
  .bm-menu {
    background: darksalmon; 
    
    /* padding: 2.5em 1.5em 0;  */
    font-size: 1.5em;
  }
   
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill:rgb(121, 66, 66);
  }
   
  /* Wrapper for item list */
  .bm-item-list {
    /* padding: 0.8em; */
    color: brown;
    font: italic bold 15px/30px Georgia, serif;
    cursor: pointer;
    text-align: right;
  }

  .bm-menu-wrap{
    width: 35%!important;
  }
   
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
   
  /* Styling of overlay */
  /* .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  } */

a:link, a:visited {
    
    color: rgba(121, 66, 66);
    padding: 14px 25px; 
    font-weight: bold;
    text-align: center; 
    text-decoration: none;
    display: inline-block;
    
}

a:hover, a:active {
    background-color: #4f352f;
}


@media only screen and (max-width: 500px) {
  /* body {
      background-color:dimgray;
  } */
  .bm-burger-button {
      display: block;
  }
  .bm-menu{
      display: block;
  }
  .bm-cross-button{
      display: block;
  }
  .header{
      display: none;
  }
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-m-1 {width: 8.33%;}
  .col-m-2 {width: 16.66%;}
  .col-m-3 {width: 25%;}
  .col-m-4 {width: 33.33%;}
  .col-m-5 {width: 41.66%;}
  .col-m-6 {width: 50%;}
  .col-m-7 {width: 58.33%;}
  .col-m-8 {width: 66.66%;}
  .col-m-9 {width: 75%;}
  .col-m-10 {width: 83.33%;}
  .col-m-11 {width: 91.66%;}
  .col-m-12 {width: 100%;}

  .bm-burger-button {
      display: none;
  }
  .bm-menu{
      display: none;
  }
  .bm-cross-button{
      display: none;
  }
  .header{
      display: none;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .bm-burger-button {
      display: none;
  }
  .bm-menu{
      display: none;
  }
  .bm-cross-button{
      display: none;
  }
  .header{
      display: block;
  }
}

@media only screen and (orientation: landscape) {
  /* body {
      background-color: lightgray;
  } */

  .bm-burger-button {
      display: none;
  }
  .bm-menu{
      display: none;
  }
  .bm-cross-button{
      display: none;
  }
  .header{
      display: block;
  }
}

/* .hide{
  display:none;
}
.show{
  display:block;
}
.right{
  float:right
} */