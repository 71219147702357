layout-table
{
    display : table;
    clear : both;
    table-layout : fixed;
    width : 100%;
}

layout-table:unresolved
{
    color : Orange;
    border: 1px black solid;
    empty-cells : show;
}

layout-header, layout-footer
{
    display : table-row;
    clear : both;   
    empty-cells : show;
    width : 100%;
    background-color: #f5f5f5;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

layout-row
{
    display : table-row;
    clear : both;   
    empty-cells : show;
    width : 100%;
    background-color: #f5f5f5;
    
}

layout-column 
{ 
    display : table-column;
    float : left;
    width : 25%;
    min-width : 25%;
    empty-cells : show;
    box-sizing: border-box;
    /* border: 1px solid white; */
    padding : 1px 1px 1px 1px;
}

layout-row:nth-child(even)
{ 
    background-color : lightyellow;
}

layout-row:hover 
{ background-color: rgb(131, 131, 32) }

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: orangered;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


  /* Slider - carousel section */

  .QmVdP {
      background-color: #460202 !important;
      box-shadow: 0 8px 10px orange !important;
  }

  .guTiMg{
      width: 85% !important;
  }

  .sc-AxirZ{
    width: 200px !important;
  }

  .gEIJqc{
    background-color: #703030 !important;
    box-shadow: 0 8px 10px orange !important;
  }